/* eslint-disable max-lines */
/* eslint-disable @next/next/no-img-element */
'use client';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useState } from 'react';

import style from './Drawer.module.css';
import wixLogo from './wix-logo.svg';

import { useUserProfile } from '../../../context/userTokens';
import { useSupport } from '../Support/intercom';

import Calendar from '@/icons/components/Calendar';
import ChatBubble from '@/icons/components/ChatBubble';
import Home from '@/icons/components/Home';
import LifeBuoy from '@/icons/components/LifeBuoy';
import Settings from '@/icons/components/Settings';
import TrendUp from '@/icons/components/TrendUp';
import User01 from '@/icons/components/User01';

interface DrawerProps {}

enum DrawerGroup {
  Actions = 'actions',
  Kpis = 'kpis',
}

export const Drawer: FC<DrawerProps> = ({}) => {
  const [openGroup, setOpenGroup] = useState<DrawerGroup | null>(null);
  const pathname = usePathname();

  useEffect(() => {
    if (
      pathname.startsWith('/actions') ||
      pathname.startsWith('/centralized-efforts')
    ) {
      setOpenGroup(DrawerGroup.Actions);
    } else if (pathname.startsWith('/kpi')) {
      setOpenGroup(DrawerGroup.Kpis);
    } else {
      setOpenGroup(null);
    }
  }, [pathname]);

  const userProfile = useUserProfile();
  const support = useSupport();

  return (
    <>
      <aside className={style.layoutDrawer}>
        <img alt="Brand logo" src={wixLogo.src} style={{ width: 83 }} />

        <div className={style.innerDrawerLayout}>
          <nav className={style.drawerNavigation}>
            <Link
              href="/"
              className={classNames(style.listItem, {
                [style.listItemActive]: pathname === '/',
              })}
            >
              <Home />
              Home
            </Link>

            <div
              className={style.listItem}
              onClick={() =>
                setOpenGroup(
                  openGroup === DrawerGroup.Actions
                    ? null
                    : DrawerGroup.Actions,
                )
              }
            >
              <Calendar />
              Actions
            </div>

            <div
              className={style.kpiWrapper}
              style={
                openGroup === DrawerGroup.Actions
                  ? {}
                  : { gridTemplateRows: '0fr' }
              }
            >
              <div>
                <Link
                  href="/actions"
                  className={classNames(style.listItem, {
                    [style.listItemActive]: pathname.startsWith('/actions'),
                  })}
                >
                  All actions
                </Link>

                <Link
                  href="/centralized-efforts"
                  className={classNames(style.listItem, {
                    [style.listItemActive]: pathname.startsWith(
                      '/centralized-efforts',
                    ),
                  })}
                >
                  Centralized efforts
                </Link>
              </div>
            </div>

            <Link
              href="/companies"
              className={classNames(style.listItem, {
                [style.listItemActive]: pathname === '/companies',
              })}
            >
              <User01 />
              Companies
            </Link>

            <div
              className={style.listItem}
              onClick={() =>
                setOpenGroup(
                  openGroup === DrawerGroup.Kpis ? null : DrawerGroup.Kpis,
                )
              }
            >
              <TrendUp />
              KPIs
            </div>

            <div
              className={style.kpiWrapper}
              style={
                openGroup === DrawerGroup.Kpis
                  ? {}
                  : { gridTemplateRows: '0fr' }
              }
            >
              <div>
                <Link
                  href="/kpis"
                  className={classNames(style.listItem, {
                    [style.listItemActive]: pathname === '/kpis',
                  })}
                >
                  All KPIs
                </Link>

                <Link
                  href="/kpi-groups"
                  className={classNames(style.listItem, {
                    [style.listItemActive]: pathname.startsWith('/kpi-groups'),
                  })}
                >
                  KPI Groups
                </Link>
              </div>
            </div>

            <Link
              href="/ask-me-anything"
              className={classNames(style.listItem, {
                [style.listItemActive]: pathname.startsWith('/ask-me-anything'),
              })}
            >
              <ChatBubble />
              Ask me anything
            </Link>
          </nav>

          <footer className={style.footer}>
            <nav className={style.drawerNavigation}>
              <div className={style.listItem} onClick={() => support.toggle()}>
                <LifeBuoy />
                Support
              </div>

              <Link
                href="/settings"
                className={classNames(style.listItem, {
                  [style.listItemActive]: pathname === '/settings',
                })}
              >
                <Settings />
                Settings
              </Link>
            </nav>

            {userProfile.profile && (
              <div className={style.userBox}>
                {userProfile.profile.image ? (
                  <img
                    referrerPolicy="no-referrer"
                    className={style.userCircel}
                    alt="User profile picture"
                    src={userProfile.profile.image}
                  />
                ) : (
                  <User01 />
                )}

                <div className={style.userDetails}>
                  {userProfile.profile.name}
                  <p>{userProfile.profile.email}</p>
                </div>
              </div>
            )}
          </footer>
        </div>
      </aside>
    </>
  );
};

// Add companies?
// Add companies to kpis?
//
