import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { Fragment, ReactNode, useState } from 'react';

import styles from './Breadcrumbs.module.scss';

import Spinner from '@/components/LoadingView/Spinner';
import { useBreadcrumb } from '@/context/breadcrumb';
import SvgChevronRight from '@/icons/components/ChevronRight';
import SvgHome from '@/icons/components/Home';
import { useTitle } from '@/src/hooks/useTitle';
import { kebabCaseToSpace } from '@/src/utils';

type BreadCrumb = {
  Icon?: (props?: any) => ReactNode;
  name?: string;
  path: string;
  onClick?: () => void;
};

const createBreadcrumb = (part: string): BreadCrumb => {
  return {
    name: kebabCaseToSpace(part),
    path: `/${part}`,
  };
};

const buildBreadcrumbs = (location: string, isCollapsed: boolean) => {
  const parts = location.split('/').filter(Boolean);
  const firstPart = parts.length > 1 && parts.at(0);
  const lastPart = parts.length > 2 && parts.at(-1);
  const isCollapsible = firstPart && lastPart;
  const breadcrumbs: BreadCrumb[] = [
    {
      Icon: SvgHome,
      path: '/',
    },
    ...(isCollapsible && isCollapsed
      ? [createBreadcrumb(firstPart), createBreadcrumb(lastPart)]
      : parts.map(createBreadcrumb)),
  ];

  return { breadcrumbs, isCollapsible };
};

const BreadCrumb = ({
  crumb,
  isActive,
  isDynamic = false,
}: {
  crumb: BreadCrumb;
  isActive: boolean;
  isDynamic: boolean;
}) => {
  const { dynamicPageName } = useBreadcrumb();
  const isSlug = isDynamic && isActive;

  const Icon =
    isSlug && !dynamicPageName ? () => <Spinner size={24} /> : crumb.Icon;
  return (
    <a
      className={classNames(styles.item, {
        [styles.active]: isActive,
      })}
      href={crumb.path}
    >
      {Icon ? (
        <Icon />
      ) : (
        kebabCaseToSpace(isSlug ? dynamicPageName : crumb.name || '')
      )}
    </a>
  );
};

const Breadcrumbs = () => {
  const { isDynamic } = useBreadcrumb();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = usePathname();
  const { breadcrumbs, isCollapsible } = buildBreadcrumbs(
    location,
    isCollapsed,
  );

  useTitle(breadcrumbs.at(-1)?.name ?? '');

  return (
    <div className={styles.breadcrumbsContainer}>
      <div className={styles.breadcrumbs}>
        {breadcrumbs.map((crumb, idx) => {
          const isActive = idx === breadcrumbs.length - 1;
          return (
            <Fragment key={idx}>
              {isCollapsible && isCollapsed && isActive && (
                <>
                  <div
                    className={styles.item}
                    onClick={() => setIsCollapsed(false)}
                  >
                    ...
                  </div>
                  <SvgChevronRight />
                </>
              )}
              <BreadCrumb
                crumb={crumb}
                isActive={isActive}
                isDynamic={isDynamic}
              />
              {idx !== breadcrumbs.length - 1 && <SvgChevronRight />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
